import ScrollReveal from 'scrollreveal'

export const srConfig = (delay = 200, viewFactor = 0.25, viewOffset = {
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
}) => {
  return {
    delay,
    distance: '20px',
    duration: 500,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    opacity: 0,
    origin: 'bottom',
    reset: false,
    rotate: {
      x: 0,
      y: 0,
      // eslint-disable-next-line id-length
      z: 0,
    },
    scale: 1,
    useDelay: 'always',
    viewFactor,
    viewOffset,
  }
}

const isSSR = typeof window === 'undefined'
export const sr = isSSR ? null : ScrollReveal()

